<template>
    <div class="auth-wrapper auth-v1"
         style="padding: 0;">
        <div v-if="showAlert">
            <alert :message="alertMessage"
                   :type="alertType"></alert>

        </div>
        <div class="auth-inner main-container-width">
            <v-row>
                <v-col cols="12"
                       sm="12"
                       md="12"
                       lg="12"
                       class="mx-auto">

                    <v-card flat>
                        <app-header :details="cardDetail"></app-header>

                        <v-stepper v-model="step"
                                   class="custom">
                            <v-stepper-items>
                                <v-stepper-content step="1">
                                    <div class="mx-1 pt-4">
                                        <BackAction :text="text"
                                                    :output="output" />
                                    </div>
                                    <v-row class="mt-3 mx-7">

                                    </v-row>
                                    <v-row class="mx-lg-5 mx-md-4 mx-sm-2 mx-0 mt-8"
                                           justify="space-between">
                                        <v-col lg="6"
                                               class="">
                                            <h2 style="font-size: 20px;"
                                                class="text-color-black">{{ $t('Change Card limit') }}</h2>
                                            <span style="font-size: 12px;"
                                                  class="text-color-black">
                                                {{ $t('Card ending -') }} {{ lastFourDigits }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-form v-model="isFormValid">
                                        <v-card class="mt-2 mx-lg-5 mx-md-4 mx-sm-2 mx-0 card-issue-background">
                                            <v-row class="pa-lg-5 pa-md-4 pa-sm-2 pa-1">
                                                <v-col cols="12"
                                                       lg="8"
                                                       md="12"
                                                       sm="12">
                                                    <v-row>
                                                        <v-col cols="12"
                                                               lg="6"
                                                               md="12"
                                                               sm="12">
                                                            <div class="mb-2 text-color-black"
                                                                 style="font-size: 14px !important;">
                                                                {{ $t('Current Card limit') }}*
                                                            </div>
                                                            <v-card style="height: 49px;padding: 13px 17px; border: 1px solid rgb(231 227 252 / 35%);"
                                                                    class="d-flex">
                                                                <div width="40px">
                                                                    <v-img src="@/assets/images/card-actions/limit.png"
                                                                           class="action-image"
                                                                           contain></v-img>
                                                                </div>
                                                                <div class="mx-3 text-center">
                                                                    <span v-if="selectedLanguage == 'en'">
                                                                        <span class="text-color-black"
                                                                              style="font-weight: 300;">&nbsp;
                                                                            {{ $t(currency) }}
                                                                        </span>
                                                                    </span>

                                                                    <span :class="m3Class"
                                                                          class="text-color-black"
                                                                          style="font-weight: 300;">
                                                                        {{ creditLimit | VMask(currencyMask) }}
                                                                    </span>

                                                                    <span v-if="selectedLanguage == 'ar'">
                                                                        <span class="text-color-black"
                                                                              style="font-weight: 300;">&nbsp;
                                                                            {{ $t(currency) }}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </v-card>
                                                        </v-col>
                                                        <v-col cols="12"
                                                               lg="6"
                                                               md="12"
                                                               sm="12">
                                                            <div class="mb-2 text-color-black"
                                                                 style="font-size: 14px !important;">
                                                                {{ $t('Set new limit') }}*
                                                            </div>
                                                            <v-card style="height: 49px;padding: 13px; border: 1px solid rgb(231 227 252 / 35%);"
                                                                    class="d-flex">
                                                                <div style="cursor: pointer;"
                                                                     @click="subtract">
                                                                    <img src="@/assets/images/add.png">
                                                                </div>
                                                                <div class="mx-4 text-center"
                                                                     style="width:75%">

                                                                    <span v-if="selectedLanguage == 'en'">
                                                                        <span class="text-color-black"
                                                                              style="font-weight: 300;">&nbsp;
                                                                            {{ $t(currency) }}
                                                                        </span>
                                                                    </span>

                                                                    <span :class="m3Class"
                                                                          class="text-color-black"
                                                                          style="font-weight: 300;">
                                                                        {{ newLimit | VMask(currencyMask) }}
                                                                    </span>

                                                                    <span v-if="selectedLanguage == 'ar'">
                                                                        <span class="text-color-black"
                                                                              style="font-weight: 300;">&nbsp;
                                                                            {{ $t(currency) }}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div style="cursor: pointer;"
                                                                     @click="add">
                                                                    <img src="@/assets/images/subtract.png">
                                                                </div>
                                                            </v-card>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row>
                                                        <v-col cols="12"
                                                               lg="6"
                                                               md="8"
                                                               sm="12">
                                                            <div class="mb-2 text-color-black"
                                                                 style="font-size: 14px !important;">
                                                                {{ $t('Upload copy of salary certificate') }}*
                                                            </div>
                                                            <input id="fileUpload"
                                                                   type="file"
                                                                   value="C:\fakepath"
                                                                   hidden
                                                                   accept="image/jpg, image/png, application/pdf"
                                                                   @change="uploadImg">
                                                            <v-card style="padding: 11px 11px; border: 1px solid rgb(231 227 252 / 35%);"
                                                                    class="d-flex uplaod-img">
                                                                <div class="d-flex btn"
                                                                     @click="chooseFiles()"
                                                                     style="cursor: pointer; border: 1px solid rgb(0, 111, 207);height: 31px;padding: 2px 9px;border-radius: 8px;">
                                                                    <img v-if="!showLoader"
                                                                         src="@/assets/images/Upload-Filled.png"
                                                                         style="width: 15px;height: 14px;position: absolute;top: 20px;"
                                                                         alt="">
                                                                    <img v-if="showLoader"
                                                                         src="@/assets/images/loader-1.gif"
                                                                         alt=""
                                                                         style="width: 18px;height: 18px;position: absolute;top: 16px;">
                                                                    <button type="button"
                                                                            class="button text-blue font-weight-bold mx-4">
                                                                        <span style="padding: 0px 9px;font-weight: 200;">{{
                                                                            $t('Upload') }}</span>
                                                                    </button>
                                                                </div>
                                                                <span v-if="!closeIcon"
                                                                      class="text-color-black d-flex"
                                                                      style="font-size: 13px;padding: 6px 9px;">
                                                                    {{ $t(FileName) }}
                                                                    <v-icon v-if="closeIcon">
                                                                        {{ icons.mdiClose }}
                                                                    </v-icon>
                                                                </span>

                                                                <div v-if="closeIcon"
                                                                     class="text-color-black d-flex"
                                                                     style="font-size: 13px;padding: 6px 9px;">
                                                                    <div class="tooltip">{{ FileName.substring(0, 21) +
                                                                        "....."
                                                                    }}
                                                                        <span class="tooltiptext">{{ FileName
                                                                        }}</span>
                                                                    </div>
                                                                    <v-icon style="cursor: pointer;"
                                                                            @click="close"
                                                                            v-if="closeIcon">
                                                                        {{ icons.mdiClose }}
                                                                    </v-icon>
                                                                </div>
                                                            </v-card>
                                                        </v-col>

                                                        <v-col cols="12"
                                                               lg="6"
                                                               md="8"
                                                               sm="12">
                                                            <div class="mb-2 text-color-black"
                                                                 style="font-size: 14px !important;">
                                                                {{ $t('Preferred contact method') }}*
                                                            </div>
                                                            <v-select v-if="selectedLanguage == 'en'"
                                                                      :items="items"
                                                                      v-model="contactMethod"
                                                                      :rules="genericRules"
                                                                      solo
                                                                      outlined
                                                                      :label="$t('Email')">
                                                                <template #message="{ message }">
                                                                    {{ $t(message) }}
                                                                </template>
                                                            </v-select>
                                                            <v-select v-if="selectedLanguage == 'ar'"
                                                                      :items="itemsAr"
                                                                      v-model="contactMethod"
                                                                      :rules="genericRules"
                                                                      solo
                                                                      outlined
                                                                      :label="$t('Email')">
                                                                <template #message="{ message }">
                                                                    {{ $t(message) }}
                                                                </template>
                                                            </v-select>
                                                        </v-col>
                                                    </v-row>

                                                    <div class="d-flex justify-space-between mt-6">
                                                        <p class="mb-2 text-color-black">
                                                            {{ $t('Additional information*') }}
                                                        </p>
                                                        <p class="mb-2 text-black font-weight-regular">
                                                            {{ information.length }}{{ $t('/500') }}
                                                        </p>
                                                    </div>
                                                    <v-row>
                                                        <v-col lg="12"
                                                               md="12"
                                                               sm="12">
                                                            <v-textarea v-model="information"
                                                                        solo
                                                                        :rules="noSpecialCharsRule"
                                                                        maxlength="500"
                                                                        outlined
                                                                        :label="$t('Add Message')">
                                                                <template #message="{ message }">
                                                                    {{ $t(message) }}
                                                                </template>
                                                            </v-textarea>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row>
                                                        <v-col cols="12"
                                                               lg="4"
                                                               md="6"
                                                               sm="12">
                                                            <v-btn color="primary"
                                                                   x-large
                                                                   @click="submitIssue"
                                                                   :disabled="!isFormValid"
                                                                   block>
                                                                {{ $t('Submit') }}
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col cols="12"
                                                               lg="4"
                                                               md="6"
                                                               sm="12">
                                                            <v-btn class="text-blue"
                                                                   @click="backPage"
                                                                   style="border: 1px solid #016FD0;"
                                                                   x-large
                                                                   block>
                                                                {{ $t('Back') }}
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>

                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-form>
                                </v-stepper-content>



                                <v-stepper-content step="2">

                                    <v-row class="text-center success-message">
                                        <v-col lg="12"
                                               class="text-center">
                                            <div class="circle">
                                                <img src="@/assets/images/Success.png"
                                                     alt=""
                                                     width="70px">
                                            </div>


                                            <h2 style="font-size: 20px;"
                                                class="mt-5">
                                                {{ $t(`changeCardLimitConfirmationPageTitle`) }}</h2>

                                            <v-row class="pa-12">
                                                <v-col lg="12"
                                                       class="text-center">
                                                    <v-btn class=""
                                                           style="width: 100%;"
                                                           color="primary"
                                                           @click="goback"
                                                           type="button">
                                                        {{ $t('Done') }}
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </v-card>
                </v-col>
            </v-row>

        </div>

    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline


import AppHeader from '../test/AppHeader.vue'
import soapServices from '@/services/soap-services'
import store from '@/store';
import moment from 'moment'
import { DeviceUUID } from 'device-uuid'
import sharedServices from '@/services/shared-services';
import router from '@/router'
import tripController from '../../@core/utils/tripController'
import BackAction from '@/@core/BackAction.vue';
import alert from '../alert.vue';
import getArabicNumbers from '@/@core/utils/getArabicNumbers';
import soapErrorMessages from '@/@core/utils/soap-error-messages';
import restErrorMessages from '@/@core/utils/rest-error-messages'
import
{
    mdiClose
} from '@mdi/js'
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { getAnalytics, logEvent } from "firebase/analytics";

const currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: true,
    includeThousandsSeparator: true,
    allowNegative: false,
});

export default {
    components: {
        AppHeader,
        BackAction,
        alert
    },

    data: () => ({
        analytics: getAnalytics(),
        icons: {
            mdiClose
        },
        isFormValid: false,
        cardDetail: null,
        showLoader: false,
        currencyMask,
        step: 1,
        dialog: false,
        radioGroup: 0,
        currency: '',
        allCards: [],
        cards: [],
        date: null,
        items: ['Via Mobile', 'Via Email'],
        itemsAr: ['عبر الهاتف المحمول', 'عبر البريد الالكتروني'],
        creditLimit: null,
        newLimit: null,
        date1: null,
        information: '',
        menu: false,
        lastFourDigits: null,
        contactMethod: '',
        fileUploded: false,
        closeIcon: false,
        engCount: null,
        FileName: 'Max file size 5MB',
        modal: false,
        menu2: false,
        counter: 0,
        selectedLanguage: 'en',
        menu3: false,
        membershipRewards: '',
        genericRules: [
            v => !!v || 'Field is required'
        ],
        reader: new FileReader(),
        text: 'Back to Card Details',
        output: false,
        alertMessage: '',
        alertType: 'error',
        showAlert: false,
        newFileName: 'Max file size 5MB',

        noSpecialCharsRule: [
            (value) => !!value || 'Field is required',
            (value) =>
            {
                const specialCharsRegex = /[!@#$%^&*(),.?":{}|<>]/;
                if (specialCharsRegex.test(value))
                {
                    return 'Special characters are not allowed.';
                }
                return true;
            }
        ]

    }),
    computed: {
        m3Class()
        {
            if (this.selectedLanguage === 'ar')
            {
                return 'mr-3';
            } else
            {
                return 'ml-3';
            }
        },
    },
    created()
    {
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
            }
        })
    },
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang)
        {
            this.selectedLanguage = localStorage.getItem('language');
        }
        this.cardDetail = store.get('card/details');
        this.$route.params.cardType == 'primary' ? this.cardDetail = store.get('card/details') : this.cardDetail = store.get('card/supplementory')
        window.scrollTo(0, 0);
        this.selectedCard = store.get('card/details');
        debugger;
        this.creditLimit = (parseInt(this.cardDetail.creditLimit.amt));
        this.currency = this.cardDetail.creditLimit.cur
        this.lastFourDigits = this.cardDetail.Last4Digits
        this.newLimit = (parseInt(this.cardDetail.creditLimit.amt));
    },

    methods: {
        goback()
        {
            window.history.back();
            window.scrollTo(0, 0);
        },
        submit(e)
        {
            if (e.key == 'Backspace')
            {
                if (this.information)
                {
                    if (this.selectedLanguage == 'en')
                    {
                        if (this.counter != 0)
                        {
                            this.counter = this.counter - 1;
                        }
                    }
                    else
                    {
                        if (this.engCount != 0)
                        {
                            this.counter = this.engCount ? this.engCount - 1 : this.counter - 1;
                            this.engCount = this.counter;
                            let count = String(this.counter);
                            this.counter = getArabicNumbers.getArNumber(count);
                            if (this.counter == '۰')
                            {
                                this.counter = 0;
                                this.engCount = 0;
                            }
                        }
                    }
                }
                else
                {
                    this.counter = 0;
                    this.engCount = 0;
                }


            }
            else
            {
                if (this.selectedLanguage == 'en')
                {
                    this.counter = this.counter + 1
                }
                else
                {
                    this.counter = this.engCount ? this.engCount + 1 : this.counter + 1;
                    this.engCount = this.counter;
                    this.counter = String(this.counter);
                    this.counter = getArabicNumbers.getArNumber(this.counter);
                }

            }
        },
        close()
        {
            this.FileName = 'Max file size 5MB';
            this.fileUploded = false;
            this.closeIcon = false;
            document.getElementById("fileUpload").value = null
        },
        subtract()
        {
            if (this.newLimit >= 500)
            {
                this.newLimit = this.newLimit - 500;
            }
        },
        add()
        {
            this.newLimit = this.newLimit + 500;
        },
        home()
        {
            this.step = 0;
            window.scrollTo(0, 0);
        },
        chooseFiles: function ()
        {
            document.getElementById("fileUpload").click()
        },
        uploadImg(event)
        {
            let file = event.target.files[0];
            return new Promise((resolve, reject) =>
            {
                const allowedExtensions = ['.jpg', '.png', '.pdf'];
                const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
                let fileSize = file.size / 1024 / 1024;
                if (fileSize <= 5)
                {
                    if (allowedExtensions.includes(fileExtension))
                    {
                        const reader = new FileReader();
                        reader.readAsDataURL(event.target.files[0]);
                        reader.onload = () =>
                        {
                            let fileName = file.name.replace(/[^a-zA-Z ]/g, "");

                            let base64Image = reader.result;
                            base64Image = base64Image.replace(/^data\:([^\;]+)\;base64,/gmi, '');
                            this.uploadcardLimitImage(base64Image, file.size, fileName)
                        };
                    }
                    else
                    {
                        let alertMessage = 'Max file size: 5MB | Format: png, jpg and pdf'
                        this.alertMessage = alertMessage
                        this.alertType = 'error'
                        this.showAlert = true
                        setTimeout(() =>
                        {
                            this.showAlert = false
                        }, 5000)
                    }
                }
                else
                {
                    let alertMessage = 'File is too large, maximum file size is 5MB'
                    this.alertMessage = alertMessage
                    this.alertType = 'error'
                    this.showAlert = true
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                }

            });
        },
        uploadcardLimitImage(img, size, name) // xml request payload to soap-services
        {
            this.showLoader = true;
            let obj = {
                idSeed: sharedServices.getIdSeed(),
                sysId: new DeviceUUID().get(),
                messageStamp: moment(new Date()).format('DD/MM/yyyy HH:mm:ss'),
                sysAuth: store.get('requestKeys/accessToken'),
                correlationID: Math.floor(1000 + Math.random() * 9000).toString(),
                requestorID: localStorage.getItem('userName'),
                base64Binary: img,
                filename: 'assets.jpg',
                length: size
            }

            soapServices.changeCardLimit(obj).then(soapResp =>
            {
                if (soapResp?.SaveDocumentResponse?.SaveDocumentResult?.IntDocService?.Result?.ErrorCode == '000')
                {
                    this.newFileName = soapResp?.SaveDocumentResponse?.SaveDocumentResult?.IntDocService?.Body?.FileName
                    this.fileUploded = true;
                    this.FileName = name
                    let alertMessage = 'File uploaded Successfully';
                    this.alertMessage = alertMessage
                    this.alertType = 'success'
                    this.showAlert = true
                    this.closeIcon = true;
                    this.showLoader = false;
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                }
            })
        },
        backPage()
        {
            router.push('/manage-card-details')
            window.scrollTo(0, 0);
        },

        submitIssue()
        {
            // if (this.newLimit < this.creditLimit || this.newLimit == this.creditLimit)
            // {
            //     this.showAlert = true;
            //     let alertMessage = 'Please set your limit greater then previous limit'
            //     let errorCode = '1'
            //     this.alertMessage = restErrorMessages.get(errorCode, alertMessage);
            //     this.alertType = 'error'
            //     setTimeout(() =>
            //     {
            //         this.showAlert = false
            //     }, 5000)
            //     return
            // }
            if (this.contactMethod == '')
            {
                this.showAlert = true;

                let errorCode = 2
                this.alertMessage = restErrorMessages.get('2', 'Please add contact method');
                this.alertType = 'error'
                setTimeout(() =>
                {
                    this.showAlert = false
                }, 5000)
                return;

            }
            if (this.information == '' || this.information == null)
            {
                this.showAlert = true;

                let errorCode = '3'
                this.alertMessage = restErrorMessages.get(errorCode, 'Please add information');
                this.alertType = 'error'
                setTimeout(() =>
                {
                    this.showAlert = false
                }, 5000)
                return;

            }
            if (!this.fileUploded)
            {
                this.showAlert = true;

                let errorCode = '4'
                this.alertMessage = restErrorMessages.get(errorCode, 'Please select a file');
                this.alertType = 'error'
                setTimeout(() =>
                {
                    this.showAlert = false
                }, 5000)
                return;

            }
            let obj = {
                correlationID: Math.floor(1000 + Math.random() * 9000).toString(),
                messageStamp: moment(new Date()).format('DD/MM/yyyy HH:mm:ss'),
                sysId: new DeviceUUID().get(),
                sysAuth: store.get('requestKeys/accessToken'),
                requestorID: localStorage.getItem('userName'),
                idSeed: sharedServices.getIdSeed(),
                custNo: store.get('requestKeys/ClientCode'),
                accNo: this.cardDetail.accountNo,
                cardNo: this.cardDetail.primeCardSerno,
                contactMethod: this.contactMethod == 'Email' ? 'E' : 'M',
                newLOC: this.newLimit,
                clientMessage: this.information,
                filename: this.newFileName,
                sessionId: this.newLimit > this.creditLimit ? '0' : '1'
            }

            soapServices.submitForChangeLimit(obj).then(soapResp =>
            {
                if (soapResp?.SRLOCIncreaseResponse?.SRLOCIncreaseResult?.ServiceRequest?.Result?.ErrorCode == '000')
                {
                    this.step++
                    window.scrollTo(0, 0);

                    logEvent(this.analytics, 'AMEX_CI_CARD_LIMIT_SUCCESS', {
                        content_type: 'userName',
                        content_id: store.get('requestKeys/userName')
                    })
                } else
                {
                    logEvent(this.analytics, 'AMEX_CI_CARD_LIMIT_FAILURE', {
                        content_type: 'userName',
                        content_id: store.get('requestKeys/userName')
                    })

                    this.showAlert = true;
                    let alertMessage = soapResp?.SRLOCIncreaseResponse?.SRLOCIncreaseResult?.ServiceRequest?.Result?.ErrorDesc;
                    let errorCode = soapResp?.SRLOCIncreaseResponse?.SRLOCIncreaseResult?.ServiceRequest?.Result?.ErrorCode
                    this.alertMessage = soapErrorMessages.get(errorCode, alertMessage);
                    this.alertType = 'error'
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                }
            })
        },



        next()
        {
            this.step++;
            window.scrollTo(0, 0);
        },

    }

}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.action-image {
    height: 35px;
    width: 41px;
    margin: -7px -5px;
    // top: 6px;
}

::v-deep .v-application.theme--light .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    /* box-shadow: 0 1px 3px 0 rgb(94 86 105 / 20%), 0 3px 1px -2px rgb(94 86 105 / 12%), 0 2px 2px 0 rgb(94 86 105 / 14%); */
    box-shadow: none !important;
}


.v-application.theme--light .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}

.success-message {
    width: 40%;
    margin: 0 auto;
}

.area {
    background-color: white;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 193px;
    background-color: #0a0808;
    color: white;
    /* text-align: center; */
    border-radius: 6px;
    padding: 0.1rem 0.5rem 0.3rem;
    height: 2rem;
    position: absolute;
    z-index: 1;
    font-size: -43rem;
    font-size: -5rem;
    text-align: start;
    top: -3rem;
    font-size: 0.6rem;
    left: 1rem;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.circle {
    background: #E2F1FD;
    width: 118px;
    height: 115px;
    margin: 0 auto;
    padding: 31px 0px;
    border-radius: 70px;

    img {
        width: 55px;
    }
}

@media screen and (max-width: 1000px) {
    .success-message {
        width: 100%;
        margin: 0 auto;
    }

}
</style>
